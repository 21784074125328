// CARGA DE DATOS
// hay 2 tipos: sincronizado (sync=true)
//              a peticion (con disparo)

// SINCRONIZADO: 
// propiedades afectadas:
// sync=true
// syncAccion: acción a ejecutar para cargar los records. Si no recibe nada se utiliza lo que haya definido en autoLoad
// masterStore (indica el store al que se sincroniza masterStrore.syncRecord)
// masterRecordField (campo del  masterStore.syncRecord  que utilizamos para sincronizar, por defecto id)

// variables:
//autoLoad

// WACTHS
// watch masterField (masterField es un computed de masterStore.syncRecord[masterRecordField])
// si hay cambios ejecuta this.watchAccion(); 

// metodos
// this.watchAccion(origen, accion=this.syncAccion):   
// setStoreAccion(): guardamos accionCt y acción en Store
// watchAccionExec(): ejecutamos accion guardada

// watchAccionExec()
// evaluamos accion (computed)    
// si su valor='' --> ejecuta recordGet(Autoload) (autoLoad se inicializa en el particular con un nombre de buscar para la API)
// si tiene valor --> ejecuta recordGet(SyncAccion)

// recordsGet(buscar): (carga de registros del Grid)
// llamada a la API (con controles del filtro del servidor)
// getDataAux(): Es opcional, se define en el particular y se usa para cargar datos auxiliares
// recordsGetAfter(): Es opcional, se define en el particular y se llama despues de cargar datos
// aqui pondríamos por ejemplo un filtrado a nivel de cliente


// Por peticion :
// propiedades afectadas:
// sync=false
// syncAccion: acción a ejecutar para cargar los records. Si no recibe nada se utiliza lo que haya definido en autoLoad
// syncDisparo: switch que al cambiarlo ejecuta el disparo del componente          
// masterStore (indica el store master)
// masterRecordField (campo del  masterStore.syncRecord  que utilizamos para sincronizar, por defecto id)    


// WATCHS
// watch syncDisparo. (si cambia ejecuta el watchAccion)

// metodos
// igual que el sincronizado
//------------------------------------------------------------------------------------------------------------------------------


//TRATAMIENTO DE ACCIONES
//variables
// this.syncAccionSend (se pasará a una de propiedad al mantenimiento asociado ej. syncAccion=syncAccionSend)
// this.syncDisparoSend (se pasará a una de propiedad al mantenimiento asociado)   

// ej. accion F (VER/NUEVO  M/MX)
// Metodos
// Inicio accion pulsando boton VER/NUEVO baseBtraMto
// entrada de execAccion(ver/nuevo)
// this.$store.commit(this.storeName + '/recordsSelectedSet', evt.item? [evt.item] : [{ id:idAccionNuevo }]);     
// cambiamos propiedad syncDisparoSend del componente asociado
// cambiamos propiedad syncAccionSend (si es opcional o hay varias acciones posibles)
// el WATCH del componente asociado debe reaccionar al syncDisparo
// this.syncAccionSend= evt.accion;
// this.syncDisparoSend= !this.syncDisparoSend; 


// TIPOS DE FINDER CONTROL Y MANTENIMIENTO ASOCIADO
// tipoView: se define en los ctrlFinders. 
// puede ser de 3 tipos:
// 'FC': finder control sin Mto (solo btn extraer)
// 'FCV': finder control tal y como esté configurado (con su Finder/Mto) o lo que sea (btns de extraer + ver) en el mantenimiento asociado, 
//        posibilidad de elimnar botonera de mantenimiento y solo deje ver
// 'FCA': finder con mantenimiento virtual (btns de extraer + ver)

// TIPOS DE FINDER Y MANTENIMIENTO ASOCIADO
// tipoView:   
// puede ser de 4 tipos:
// FP  Finder con PERMISOS mview/mViewDefault (standard)
// FNP Finder sin PERMISOS mview/mViewDefault  
// FA  Finder con mto virtual (mviewArray)
// FL  Finder lista (NADA, solo lista)   


// mView: nombre manteninimiento asociado`
// ctrlCt: array ctde controles normalmente para un control Finder




import { mixCt } from "@/mixins/mixCt";
import { mixBtras } from "@/mixins/mixBtras";
import storeF from '@/stores/modulos/storeF.js';

export var mixF = {
  mixins: [mixCt, mixBtras],
  props: {
    sync: { type: Boolean, default: false },              // sincronización con el MasterStore. True, el componente recarga datos cada vez que cambia una fila.
    syncAccion: { type: String, default: '' },            // acción a ejecutar para cargar los records. Si no recibe nada, se utiliza lo que haya definido en autoLoad
    syncDisparo: { type: Boolean, default: false },       // switch que al cambiarlo ejecuta el disparo del componente          
    storeRaiz: { type: String, default: "" },             // raiz del store
    masterStore: { type: String, default: '' },           // nombre del store asociado
    masterRecordField: { type: String, default: 'id' },   // campo relacionado con el Maestro   
    storeAux: { type: String, default: "" },              // store auxiliar
    recordAux: { type: Object, default: null },           // record auxiliar
    IDAux: { type: [Number, String], default: '' },       // id auxiliar necesario para recopilar datos externos se definen en dataAux
    tipoView: { type: String, default: 'FP' },             // Por defecto finder con permisos (FP)
    mView: { type: [Object, String], default: '' },        // mto que carga opcional puede ser de un ctrlfinder (puede ser virtual - array de controles)
    ctrlCt: { type: Array, default: () => [] },             // array ct del control
    readOnly: { type: Boolean, default: false },            // obliga al mto como solo lectura (omite los permisos)
    viewMtoOnly: { type: Boolean, default: false }         // muestra mto sin mostrar el finder
  },


  data() {
    return {
      raiz: '',                        // store raiz name para pasar
      storeName: '',                   // store name particular (storeRaiz + stIni.api + (index si storeRaiz== '')
      ct: 0,                           // array de controles
      apiArgs: {},                     // objeto donde están definidas las llamadas a la API
      dataAux: {},                     //
      //
      btExtCfg: null,                 // (design, btnsAccion). Configuración botonera Extra
      btRowCfg: null,                  // (design, btnsAccion). Configuración botonera row
      btGridCfg: null,                 // (design, btnsAccion). Configuración botonera Top Grid           
      autoLoad: '',                    // acción API de autocarga    

      componenteDinamico: null,        // nombre del Mto dinámico
      loaded: false,                   // switch que indica si han terminado las cargas asíncronas para cargar el template

      syncDisparoSend: false,          // switch que al cambiarlo ejecuta el disparo del componente asociado (sólo si se pasa como propiedad)
      syncAccionSend: '',              // acción a ejecutar en el componente asociado (sólo si se le pasa como propiedad)     
    };
  },


  async created() {
    console.log(`DEV ${this.stIni.api} ********************** (created), viewMtoOnly:${this.viewMtoOnly}`);

    if (this.viewMtoOnly) {      
      this.loadedComponent();
      return;
    }

    // inicializo datos auxiliares generales (Mixin)
    this.iniDataMixin();

    // inicializo apiArgs
    this.iniDataApi();

    // inicializo variables Btra según tipo de componente
    this.iniBtra();

    // registro Store
    this.registerStore(storeF);

    // inicializo store (stIni y getSchema)
    await this.iniStore();

    // paso schema a controles
    this.ct = JSON.parse(JSON.stringify(this.sch));

    // inicializo datos particulares
    await this.iniDataParticular();

    // funciones a ejecutar una vez cargado totalmente el componente
    this.loadedComponent();


    //     
    // watch's:
    if (this.sync) {
      // si soy un finder asociado sincronizado ejecutaré este watch cada vez que cambie mi masterField 
      // si soy un finder no asociado no creo este watch, por lo que nunca se ejecutará     
      this.$watch("masterField", {
        immediate: true,
        handler() {
          console.log("watch MASTERFIELD " + this.stIni.api + ': ' + this.masterField);
          this.watchAccion('masterField');
        }
      });

    } else {
      // si soy un finder no asociado ejecutare este watch cuando se cree (debido al imediate true), pero solo una vez (ej. vengo directamente de menu)
      // si soy un finder asociado (no sincronizado) ejecutare este watch cada vez que cambie mi syncDisparo
      this.$watch("syncDisparo", {
        handler() {
          console.log("watch SYNCDISPARO " + this.stIni.api);
          this.watchAccion('syncDisparo');
        }
      });
    }

  },


  //
  methods: {

    // inicializo Data Generales
    iniDataMixin() {
      console.log("DEV " + this.stIni.api + " ********************** (iniDataMixin)");

      //
      this.apiArgs = {
        // sch devuelve un array con los permisos de todos los asocM
        // Sin parámetro array asocM, nos devuelve los permisos del mto definido en el API particular php
        sch: ["finder", 'getSchema', {}, this.stIni.api],        
        get: ["finder", 'recordsGet', { ctrls: {}, accion: '' }, this.stIni.api]
      }
    },

    // override de apiArgs en particular en caso que sea necesario
    iniDataApi() { },

    // inicializo Btra y variables según tipo de componente
    iniBtra() {
      this.btExtCfg = JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));

      // FinderControl
      if (this.tipoView.substr(0, 2) === 'FC') {
        this.iniBtraFinderControl();

        // Finder
      } else {
        this.iniBtraFinder();
      }
    },

    iniBtraFinder() {
      // finder, por defecto: FP
      // FP  PERMISOS mview/mViewDefault        
      // FNP VER mview/mViewDefault  
      // FA  VER mviewArray 
      // FL  NADA (sólo lista) 

      // (FL) FINDER LISTA
      if (this.tipoView == 'FL') return;


      // Botones Grid TOP
      // botonera grid mto (por ahora solo alta) activo segun permiso
      // OJO. Si reemplazamos la variable btGridCfg en el particular, preguntar antes si es readOnly
      // para no hacer la asignación      
      if (!this.readOnly) this.btGridCfg = JSON.parse(JSON.stringify(this.$cfe.btra.GRID));

      // Botones ROW
      // por ahora solo boton ver
      this.btRowCfg = JSON.parse(JSON.stringify(this.$cfe.btra.FINDER));
    },

    iniBtraFinderControl() {
      //finder control, por defecto: FC
      // FC   EXTRAER     mView/mViewdefault  
      // FCV  EXTRAER/VER mView/mViewdefault              
      // FCA  EXTRAER/VER mViewArray (virtual)

      // inicializamos btRowCfg con boton extraer
      this.btRowCfg = JSON.parse(JSON.stringify(this.$cfe.btra.FC));

      // (FC) por defecto
      if (this.tipoView == 'FC') return;

      // FINDER CONTROL (con vista)
      // FCA, FCV
      // Añadimos boton ver a la btRowCfg
      this.btRowCfg.btnsAccion = this.btRowCfg.btnsAccion.concat(
        { "accion": 0, "btn": "ver" }
      );

    },

    // inicializo datos particulares
    iniDataParticular() {},


    // entro cuando el componente se ha cargado
    loadedComponent() {
      
      // indico que el componente ya se ha cargado
      this.loaded = true;

      // campo de un ctrlfinder a mantener su valor cada vez que se muestra
      if (this.stIni.field2Preserve && this.ct[this.stIni.field2Preserve]) {
        this.$set(this.ct[this.stIni.field2Preserve], 2, this.ctrlFGet());
      }

      // viewMtoOnly:true (normalmente viene de un ctrlF)
      // asigno nombre componente dinámico y establezco como store el raiz recibido
      if (this.viewMtoOnly) {
        this.componenteDinamico = this.mViewGet;
        
        // Pendiente: añadirle nº a storeRaiz si ya existe commonF
        this.storeName = this.storeRaiz + 'View';        
        return;
      }
      
      // emito evento por loaded por si lo recoge alguna funcion en el padre
      // por si hay que realizar alguna operación después de la carga del componente
      this.$emit('loaded', this.storeName);

      // si el componente esta sincronizado, el watch del masterField tiene un inmediate:true que 
      // lanzaría automáticamente el watchAccion. Cortarlo aquí evita que se vuelva a repetir al hacer la carga del componente
      if (this.sync) return;
      this.loadedAccion();
    },

    //
    loadedAccion() {
      // call del watch loaded cuando no es un componente sincronizado
      // funcion que se ejecuta cuando esta cargado el componente
      this.watchAccion('loaded');
    },


    //
    async watchAccion(origen, accion = this.syncAccion) {
      console.log("DEV " + this.stIni.api + ": watchAccion (MIXF). Origen: ", origen, ', Accion: ', accion);

      //origen=='syncDisparo'
      //origen=='masterField'
      //origen=='loaded'
      //origen=='filtro'
      //origen=='disparoSt'
      this.setStoreAccion(accion, {});
      await this.watchAccionExec(origen);
    },

    // guardo acción en storeF
    setStoreAccion(accion) {
      console.log("DEV " + this.stIni.api + ": setStoreAccion");

      this.$store.commit(this.storeName + '/accionSet', { accion: accion });
    },

    // this.accion es un computed del store state.accion
    // ejecuto acción   
    async watchAccionExec(origen) {
      console.log("DEV " + this.stIni.api + ": watchAccionExec (MIXF). Accion: ", this.accion, ", Origen: ", origen);
      
      // si no se recibe ni accion ni ID, blanquea registros
      if (!this.masterField && !this.accion) {
        console.log('Blanquea recordSet...');
        this.$store.commit(this.storeName + '/recordSet', []);
        //return;
      }
      
      // muestro ventana de Mto
      if (!this.sync) this.$store.commit(this.storeName + '/modalSet', true);


      // si existe una funcion particular con el nombre de la accion, la ejecuto
      // si no existe funcion, hago recordsGet(accion)
      // si no tengo accion pero tengo autoLoad, ejecuto autoLoad
      // si no recibo accion ni autoLoad, no hago nada
      if (this.accion) {
        if (this.$isFunction(this[this.accion])) {
          return this[this.accion]();
        }
      }
      
      // obtengo registros GRID según accion / autoLoad
      await this.recordsGet(origen);
    },


    // función de carga de datos de la API en función de la acción recibida
    async recordsGet(origen) {
      console.log("DEV " + this.stIni.api + ": recordsGet (MIXF). Accion: ", this.accion, ", Origen: ", origen);
    
      // gancho antes de hacer el recordsGet
      if (!await this.recordsGetBefore()) return;
    
      // obtengo datos a filtrar      
      let filtro = this.ctrl2record(this.ct, {}, false);
                
      // actualizo accionCt en el storeF
      this.$store.commit(this.storeName + '/accionCtSet', filtro);
    
      // obtengo registros
      await this.callRecordsGet(filtro, origen);
    },

    async callRecordsGet(filtro = {}, origen= "") {
      // guarda acción a buscar
      let busqueda = this.accion ? this.accion : this.autoLoad;
      
      console.log("DEV " + this.stIni.api + ": callRecordsGet (MIXF). Búsqueda: ", busqueda, ', getRecordsArray: ', this.getRecordsArray, ', Origen: ', origen);
    
      // si no hay acción a buscar y no tiene getRecordsArray, no continua
      if (busqueda === "" && (this.getRecordsArray == undefined || this.getRecordsArray == 0)) return;
      
      // añade el ID del maestro asociado, si tiene
      this.getMasterID(filtro);

      // si el componente tiene getRecordsArray, es decir, obtiene los records
      // de otro componente en vez de hacer un apiCall para obtenerlos, y llega desde un 
      // (origen == disparoSt), obtenemos los records desde la función 'callRecordsDisparo'
      // TEMPORAL. CUANDO EXPTES SEA LA NUEVA VERSIÓN SE ACTIVA
      /* if (origen=== 'disparoSt' && this.getRecordsArray > 0) { 
        this.$store.commit(this.storeName + '/recordSet', await this.callRecordsDisparo(filtro)); 
        return; 
      } */
      
      // API call para obtener los registros
      // guarda records en store (reactivo en grid con computed:records)
      // guarda recordsSelected según el tipo de selección automática (stIni.selection)
      await this.stRecordsGet({ filtro, busqueda, origen });
    
      // gancho por si necesitamos cargar datos auxiliares
      // entro DESPUÉS de obtener los datos
      await this.getDataAux();

      // gancho final del recordsGet
      await this.recordsGetAfter();    
    },

    // gancho para búsqueda de datos particular si el origen es disparoSt y tiene getRecordsArray
    callRecordsDisparo() { return []; },

    
    //
    getMasterID(filtro) {
      filtro['masterID'] = this.masterField;
    },


    //
    getDataAux() { },

    //
    recordsGetBefore() {
      return true;
    },

    //
    recordsGetAfter() { },


    // RECIBO EVENTOS BOTONERA tanto de Mto Row como Extra
    execAccion(evt) {
      console.log("DEV execAccion " + this.stIni.api + ":", evt);

      // compruebo si la acción es un 'extraer'
      if (!this.execAccionExtraer(evt)) return;

      // compruebo si la acción es un 'nuevo'
      if (!this.execAccionNuevo(evt)) return;

      // ejecutamos la función de la acción (si existe)
      // Si viene por ejemplo (0=ver, 1=nuevo) tenemos una por defecto
      if (this.$isFunction(this[evt.accion])) return this[evt.accion](evt);

      // guarda el item  del Grid correspondiente a la accion de la botonera (row/extra..)
      // sólo si recibimos uno junto con la acción
      // this.$store.commit(this.storeName + '/recordsSelectedSet', evt.item? [evt.item] : [{ id:idAccionNuevo }]);     
      if (evt.item) this.$store.commit(this.storeName + '/recordsSelectedSet', [evt.item]);

      // si es un finder control virtual guardo el record de la fila pulsada en el schema
      if (this.tipoView == 'FCA') this.ctrlCt[4].comp.record = evt.item;

      // asigno la vista al componenteDinámico
      // cambiamos propiedad syncAccionSend (si es opcional o hay varias acciones posibles)
      // el WATCH del componente asociado debe reaccionar al syncDisparo
      this.callSyncAccion(evt);
    },


    // si la acción recibida es un 'extraer', emito evento con esa acción y salgo
    execAccionExtraer(evt) {
      if (evt.accion == 'extraer') {
        this.$emit('onEvent', evt)
        return false;
      }

      return true;
    },


    //
    execAccionNuevo(evt) {
      if (evt.accion != '1') return true;

      // Nuevo en caso de expansible
      if (this.expansibleMX) return this.execAccionNuevoMX();
      
      // nuevo 
      // blanqueo el array de records seleccionados
      this.$store.commit(this.storeName + '/recordsSelectedSet', []);
      return true;
    },

    execAccionNuevoMX() {

      // compruebo si está en edición
      if (!this.noEditMX) {
        this.$root.$alert.open('Tiene una linea en edición. GUARDE o CANCELE los cambios', 'error');
        return false;
      }

      // añado un objeto con id=0 como primer registro del records
      this.$store.dispatch(this.storeName + '/nuevoMX', 0);
      return true;

    },

    // ejecuto sincronizado con la acción recibida
    callSyncAccion(evt) {
      this.syncAccionSend = evt.accion;
      this.syncDisparoSend = !this.syncDisparoSend;
      this.componenteDinamico = this.mViewGet;
    },


    // ejecuta fn en store local
    storeUpdate(accionMasterSt, params = null) {
      if (!this.storeName) return;
      this.$store.dispatch(this.storeName + '/' + accionMasterSt, params);
    },


    // ejecuta fn en store de maestro asociado
    masterStoreUpdate(accionMasterSt, params = null) {
      if (!this.masterStore) return;
      this.$store.dispatch(this.masterStore + '/' + accionMasterSt, params);
    },


    // ---- Acciones Store ---------------------------------------------------------    
    async stRecordsGet({ filtro, busqueda, origen }) {
      // apiArg: llamada API definida en el particular. 
      // args: argumentos a fusionar con los definidos en 'args' de apiArgs

      let param = { apiArg: this.apiArgs.get, args: { ctrls: filtro, accion:busqueda } };
      console.log("stRecordsGet *************************:", param);
      return await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore, origen });
    },

    async stOrdenar({ campo, orden = 'ASC', fn = null }) {
      console.log("stOrdenar *************************:", campo, orden);
      await this.$store.dispatch(this.storeName + '/ordenarRecords', { campo, orden, fn });
    },

    async stFiltrar(fn, params) {
      await this.$store.dispatch(this.storeName + '/filtrarRecords', { fn, params });
    },

    // -----------------------------------------------------------------------------

    // RECIBO EVENTOS CABECERA (ahora mismo solo se gestiona el único que evento que tiene, que es el de 'cerrar')     
    eventHeader(evt) {
      console.log("DEV eventHeader " + this.stIni.api + ":", evt);

      // si no se recibe storeRaiz (significa que viene del menú) se hace un router al raiz
      // si se recibe, se emite el evento al padre
      if (this.storeRaiz == "") {
        this.$router.push({ path: '/' });
        this.$store.commit("set_activeMenu", []);
        return;
      }

      // emito evento de cierre al padre
      this.$emit('onEvent', { accion: 6, origen: 'Finder' });

      // si es un tipo de vista 'controlFinder', salgo
      if (this.tipoView.substr(0, 2) == 'FC') return;

      // cierro finder desde storeM
      this.$store.commit(this.storeName + '/modalSet', false);
    },

    // RECIBO EVENTOS FILTRO DE cualquier boton del TEMPLATE
    eventFiltro(accion = "") {  
      if (this.stIni.field2Preserve && this.ct[this.stIni.field2Preserve]) {
        this.$store.commit('ctrlFSet', { modulo:this.stIni.api, valor:this.ct[this.stIni.field2Preserve][2] }); 
      }
      

      // configuro campos de la cabecera del Grid según la acción recibida
      this.headerGrid = this.headerSet(accion);

      // ejecuto acción de búsqueda
      this.watchAccion('filtro', accion);
    },


    // destruyo el Store al destruir el componente
    // cuando se trata de un MX, sobreescribir esta función en el particular para evitar que 
    // destruya el Store cada vez que abre el expansible.
    // Asegurarse destruirlo en su componente asociado
    destroyStore() {
      if (typeof this.$store.state[this.storeName] === 'undefined') return;
      this.$store.unregisterModule(this.storeName);
    },


    //
    ctrlFGet(modulo= this.stIni.api) {      
      return this.$store.getters["ctrlFGet"](modulo);
    },


  },


  //
  computed: {

    // schema de controles del filtro de búsqueda
    sch() {
      return this.$store.state[this.storeName].sch;
    },

    // array de registros del Grid
    records() {
      return this.$store.state[this.storeName].records;
    },

    // array con todos los registros (sólo lectura)
    recordsRead() {
      return this.$store.state[this.storeName].recordsRead;
    },

    // acción de búsqueda
    accion() {
      return this.$store.state[this.storeName].accion;
    },

    // objeto de parámetros para la búsqueda 
    accionCt() {
      if (!this.$store.state[this.storeName]) return {};
      return this.$store.state[this.storeName].accionCt;
    },

    disparoSt() {
      if (!this.$store.state[this.storeName]) return false;
      return this.$store.state[this.storeName].disparo;
    },

    // (true/false). Mto modal visible o no (ahora mismo se utiliza a traves del componente dualTemplate)
    modal() {
      if (!this.$store.state[this.storeName]) return false;
      return this.$store.state[this.storeName].modal;
    },

    permMto() {
      return this.$store.state[this.storeName].perm;
    },

    permExtra() {
      return this.$store.state[this.storeName].permExtra;
    },

    // identifica si tiene un Mto expansible o no
    // se define el nombre del store del expansible
    expansibleMX() {
      return this.$store.state[this.storeName].expansibleMX;
    },

    // en caso de tener expansible consulta estado de edicion
    noEditMX() {
      // (true/false) edición expansible         
      // pendiente revisar cuando se pasa el mView o esta por defecto   
      let storeMX = this.storeName + this.mViewGet;

      if (!this.$store.state[storeMX]) return true;
      return this.$store.getters[storeMX + "/noEdit"];
    },

    // records seleccionados del componente maestro asociado
    masterSyncRecord() {
      return this.$store.getters[this.masterStore + "/syncRecord"];
    },

    syncRecord() {
      return this.$store.getters[this.storeName + "/syncRecord"];
    },

    // devuelvo el valor del campo masterRecordField del record sincronizado      
    masterField() {
      if (!this.masterStore) return null;
      if (!this.masterSyncRecord) return null;
      if (!this.masterSyncRecord[this.masterRecordField]) return null;      
      return this.masterSyncRecord[this.masterRecordField];
    },

    // devuelvo el valor del estado del master asociado
    masterEstado() {
      if (!this.masterStore) return '';
      return this.$store.state[this.masterStore].estado;
    },

    //
    mViewGet() {
      let nameComp = this.stIni.mView;

      // para mostrar un mantenimiento alternativo al que hay por defecto
      if (this.tipoView == 'FCA') return 'virtualView';
      if (this.mView) nameComp = this.mView;
      if (this.ctrlCt.length && this.ctrlCt[4].comp.mView) nameComp = this.ctrlCt[4].comp.mView;
      return nameComp;
    },


    // índice del record en el array
    getRecordsArray() {
      if (!this.$store.state[this.storeName]) return 0;
      return this.$store.state[this.storeName].getRecordsArray;
    },


    // tipo de selección de lineas (first, preserve, both)
    selection() {
      if (!this.$store.state[this.storeName]) return false;
      return this.$store.state[this.storeName].selection;
    },


    // records totales
    recordCount() {
      return this.records.length;
    }
    
  },


  watch: {

    // Para utilizar este disparo y ejecutar una accion, debemos asignar en el storeF accion (opcional), accionCt (opcional) y disparo del store externamente
    // para hacer un refresh solo es necesario cambiar el disparo del storeF
    // accion se guarda el nombre de la accion de busqueda (nombre API)
    // accionCt par de valores de los controles CT para llamar a la busqueda
    // se diferencia de ct en que ct es un schema y accionCt es par valor del mismo
    // 
    disparoSt() {
      if (!this.sync) this.$store.commit(this.storeName + '/modalSet', true);
      this.callRecordsGet(this.accionCt, 'disparoSt');
    },

  },

  // ELIMINO MI STORE 
  beforeDestroy() {
    this.destroyStore();
  },

}
