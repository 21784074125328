<!-- Btra Botones Extra -->

<!--
  Parámetros de los botones:
    - accion (string) (obligatorio):
      * método a llamar al pulsar el botón.
      * nombre para darle permisos a nivel de botones de Módulo.

    - btn (string): nombre del icono definido en Entorno.js.
    - svg (componente): componente icono svg importado. (Ejemplo: require('@assets/svg/compras.svg')).
    - icono (string): icono del botón. Si se define junto al botón prevalece sobre el icono de v-bind.
    - texto (string): texto a mostrar dentro del botón. Si se define junto al botón se muestra a la izda de él.
    - title (string): tooltip.
    - badge (fn): mostramos un badge al lado del botón con el texto que devuelve la fn.
    - styB (fn): texto con el estilo CSS del botón
-->

<template>
    <div class="baseBtraExtra">
      <btra
        :btra="botonera"
        :modulo="modulo"
        :showDisabled="showDisabled"
        @onEvent="eventBtns">
          <template  v-for="elem in botonera"
            v-slot:[`${elem.accion}`]="{item,show,disabled}">
              <slot :name="item.accion" :item="item" :show="show" :disabled="disabled"></slot>
          </template>

      </btra>
    </div>
</template>


<script>

    import plugs from "@/common/general_plugs";
    const btra = () => plugs.groute("baseBtra.vue", "base");

    export default {
      name:'baseBtraExtra',
      components: { btra },
      props: {
        permExtra: { type: [Array, Object], default:()=> [] },
        modulo: { type: Object, default:null },
        showDisabled: { type: [Number, Boolean], default: 1 }
      },

      data() {
        return {
          botonera:[]
        }
      },


      created() {
        this.mountBtra();
      },


      methods: {

        // gestor de eventos
        eventBtns(evt) {
          console.log("*** event_btraextra. Emit btraextra: ", evt, " ***");
          this.$emit("onEvent", evt);
        },


        // monto array de botones a mostrar
        mountBtra() {
          // obtengo la botonera Extra (la definida en modulo.btnsAccion + la btra recibida)
          // y la filtro por los permisos recibidos
          if (!this.modulo) return;
          this.botonera= this.modulo.btnsAccion.slice();

          // filtro los botones por los permisos recibidos
          this.filterPermBtns();
        },


        // filtro botón por su permiso
        filterPermBtns() {
          // si no he recibido permisos, devuelvo el array de botones sin modificarlo por permisos
          if (Array.isArray(this.permExtra)) return;

          this.botonera.forEach(elem => {
            elem.show= typeof this.permExtra[elem.accion]!== 'undefined'? Number(this.permExtra[elem.accion]) : 1;
          });

        },

      }
    };
</script>
