<!-- Mto particular de Cementerios -->

<template>
  <div class="cemenM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
       persistent>
      
        <template v-slot:controles="{}">

          <div>
    
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">      
              <div class="contenedor" style="width:1200px">

                <!-- <div v-html="info"></div> -->

                <!-- Botones Mto -->
                <div v-if="!readOnly" class="pt-2 d-flex justify-left" style="justify-content:space-between;width:360px">
                  
                  <!-- Mto -->            
                  <baseBtraMto class="conflex"             
                    :perm="permMto"
                    :modulo="btMtoCfg" 
                    :estado="estado"
                    @onEvent="execAccion">        
                  </baseBtraMto>
               
                  <!-- Extra -->            
                  <div class="conflex" style="margin-left: 50px">                         
                    <baseBtraExtra class="conflex"           
                      :permExtra="permExtra"
                      :modulo="btExtCfg"           
                      @onEvent="execAccion">             
                    </baseBtraExtra>                  
                  </div>
                             
                </div>
          

                <!-- Ventana de Documentos -->
                <!-- <common_Docs
                  :padre="stName"
                  :id="record.id"       
                  show="1"
                  tip="9">
                </common_Docs> -->
        

                <div class="conflex" style="padding-top:10px">
                  <div class="columna" style="width:60%">
                    <uiText
                      styleInput="font-weight: bold;"
                      v-model="ct.name[2]"
                      :label="ct.name[1]"
                      :disabled="noEdit"
                    ></uiText>
                  
                    <ctrlF               
                      :ct="ct.atlas"
                      :edicion="!noEdit">                        
                    </ctrlF>
              

                    <uiText
                      v-model="ct.dire[2]"
                      :label="ct.dire[1]"
                      :disabled="noEdit"
                    ></uiText>
                  
                    <ctrlF               
                      :ct="ct.fun_id"
                      :edicion="!noEdit">                        
                    </ctrlF>
                  </div>

                  <div class="columna">
                    <v-select
                      style="width:18rem"
                      v-bind="$select"
                      v-model="ct.tipo[2]"
                      :items="$store.state.datos_iniciales.tipo_cem"
                      :disabled="noEdit"
                      :label="ct.tipo[1]"
                      item-value="id"
                      item-text="name"
                    ></v-select>

                    <!-- <ctrlgeo
                      :schema="schema"
                      name_ctrl="gmap"
                      icono="mdi-map-check-outline"
                      :edicion="is_edit"
                    ></ctrlgeo> -->
                    
                    <uiUrl
                      v-model="ct.url[2]"
                      :label="ct.url[1]"
                      :disabled="noEdit">
                    </uiUrl>

                    <div style="display:flex">
                      <uiText
                        style="width:20rem"                   
                        v-model="ct.contacto[2]"
                        :label="ct.contacto[1]"
                        :disabled="noEdit"
                      ></uiText>

                      <uiText
                        style="width:10rem"                       
                        v-model="ct.contactotlf[2]"
                        :label="ct.contactotlf[1]"
                        :disabled="noEdit"
                      ></uiText>
                    </div>
                  </div>
                </div>

                <div style="display:flex;flex-wrap:wrap;justify-content:center">
                  <div class="columna pt-3">
                    <div class="cab">NICHOS TEMPORALIDAD</div>
                    <v-sheet
                      style="display:flex"
                      width="15rem"
                      height="230px"
                      v-bind="$cfg.styles.marco"
                    >
                      <div style="width:6rem; display:flex; flex-direction:column">
                        <uiText                         
                          v-model="ct.n01[2]"
                          :label="ct.n01[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                        
                          v-model="ct.n02[2]"
                          :label="ct.n02[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                       
                          v-model="ct.n03[2]"
                          :label="ct.n03[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                       
                          v-model="ct.n04[2]"
                          :label="ct.n04[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                       
                          v-model="ct.n05[2]"
                          :label="ct.n05[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <div style="width:7rem; display:flex; flex-direction:column">
                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n11[2]"
                          :label="ct.n11[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n12[2]"
                          :label="ct.n12[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n13[2]"
                          :label="ct.n13[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n14[2]"
                          :label="ct.n14[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n15[2]"
                          :label="ct.n15[1]"
                          :disabled="noEdit"
                        ></v-input_n>
                      </div>
                    </v-sheet>
                  </div>

                  <div class="columna pt-3">
                    <div class="cab">NICHOS CONCESION</div>
                    <v-sheet
                      style="width:15rem;display:flex"
                      height="230px"
                      v-bind="$cfg.styles.marco"
                    >
                      <div style="width:6rem; display:flex; flex-direction:column">
                        <uiText                     
                          v-model="ct.n21[2]"
                          :label="ct.n21[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                      
                          v-model="ct.n22[2]"
                          :label="ct.n22[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                        
                          v-model="ct.n23[2]"
                          :label="ct.n23[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                          
                          v-model="ct.n24[2]"
                          :label="ct.n24[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                        
                          v-model="ct.n25[2]"
                          :label="ct.n25[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <div style="width:7rem; display:flex; flex-direction:column">
                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n31[2]"
                          :label="ct.n31[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n32[2]"
                          :label="ct.n32[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n33[2]"
                          :label="ct.n33[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n34[2]"
                          :label="ct.n34[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.n35[2]"
                          :label="ct.n35[1]"
                          :disabled="noEdit"
                        ></v-input_n>
                      </div>
                    </v-sheet>
                  </div>

                  <div class="columna pt-3">
                    <div class="cab">COLUMBARIOS</div>
                    <v-sheet
                      style="display:flex"
                      width="15rem"
                      height="230px"
                      v-bind="$cfg.styles.marco"
                    >
                      <div style="width:6rem; display:flex; flex-direction:column">
                        <uiText                        
                          v-model="ct.c01[2]"
                          :label="ct.c01[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                        
                          v-model="ct.c02[2]"
                          :label="ct.c02[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                         
                          v-model="ct.c03[2]"
                          :label="ct.c03[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                         
                          v-model="ct.c04[2]"
                          :label="ct.c04[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                         
                          v-model="ct.n05[2]"
                          :label="ct.n05[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <div style="width:7rem; display:flex; flex-direction:column">
                        <v-input_n
                          v-bind="$input"
                          v-model="ct.c11[2]"
                          :label="ct.c11[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.c12[2]"
                          :label="ct.c12[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.c13[2]"
                          :label="ct.c13[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.c14[2]"
                          :label="ct.c14[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.c15[2]"
                          :label="ct.c15[1]"
                          :disabled="noEdit"
                        ></v-input_n>
                      </div>
                    </v-sheet>
                  </div>

                  <div class="columna pt-3">
                    <div class="cab">SEPULTURAS</div>
                    <v-sheet
                      style="display:flex"
                      width="15rem"
                      height="230px"
                      v-bind="$cfg.styles.marco"
                    >
                      <div style="width:6rem; display:flex; flex-direction:column">
                        <uiText                       
                          v-model="ct.s01[2]"
                          :label="ct.s01[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                         
                          v-model="ct.s02[2]"
                          :label="ct.s02[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                        
                          v-model="ct.s03[2]"
                          :label="ct.s03[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                         
                          v-model="ct.s04[2]"
                          :label="ct.s04[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                        
                          v-model="ct.s05[2]"
                          :label="ct.s05[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <div style="width:7rem; display:flex; flex-direction:column">
                        <v-input_n
                          v-bind="$input"
                          v-model="ct.s11[2]"
                          :label="ct.s11[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.s12[2]"
                          :label="ct.s12[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.s13[2]"
                          :label="ct.s13[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.s14[2]"
                          :label="ct.s14[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.s15[2]"
                          :label="ct.s15[1]"
                          :disabled="noEdit"
                        ></v-input_n>
                      </div>
                    </v-sheet>
                  </div>

                  <div class="columna pt-3">
                    <div class="cab">EXHUMACIONES</div>
                    <v-sheet width="14rem" height="230px" v-bind="$cfg.styles.marco">
                      <v-input_n
                        v-bind="$input"
                        v-model="ct.derechos[2]"
                        :label="ct.derechos[1]"
                        :disabled="noEdit"
                      ></v-input_n>

                      <v-input_n
                        v-bind="$input"
                        v-model="ct.reducrest[2]"
                        :label="ct.reducrest[1]"
                        :disabled="noEdit"
                      ></v-input_n>

                      <v-input_n
                        v-bind="$input"
                        v-model="ct.traslad[2]"
                        :label="ct.traslad[1]"
                        :disabled="noEdit"
                      ></v-input_n>

                      <v-input_n
                        v-bind="$input"
                        v-model="ct.reinhum[2]"
                        :label="ct.reinhum[1]"
                        :disabled="noEdit"
                      ></v-input_n>
                    </v-sheet>
                  </div>
                  <!-- Fin de fila -->
                </div>

                <div style="display:flex;flex-wrap:wrap;justify-content:center">
                  <div class="columna pt-3">
                    <div class="cab">TARIFAS</div>
                    <v-sheet width="26rem" height="180px" v-bind="$cfg.styles.marco">
                      <div style="display:flex">
                        <v-input_n
                          v-bind="$input"
                          v-model="ct.sepult[2]"
                          :label="ct.sepult[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.dinhnicho[2]"
                          :label="ct.dinhnicho[1]"
                          :disabled="noEdit"
                        ></v-input_n>
                      </div>

                      <div style="display:flex">
                        <v-input_n
                          v-bind="$input"
                          v-model="ct.dinhsepult[2]"
                          :label="ct.dinhsepult[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.dinhcolumb[2]"
                          :label="ct.dinhcolumb[1]"
                          :disabled="noEdit"
                        ></v-input_n>
                      </div>

                      <div style="display:flex">
                        <v-input_n
                          v-bind="$input"
                          v-model="ct.expedtit[2]"
                          :label="ct.expedtit[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.dcollapida[2]"
                          :label="ct.dcollapida[1]"
                          :disabled="noEdit"
                        ></v-input_n>
                      </div>

                      <div style="display:flex">
                        <v-input_n
                          v-bind="$input"
                          v-model="ct.movlapida[2]"
                          :label="ct.movlapida[1]"
                          :disabled="noEdit"
                        ></v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="ct.conserva[2]"
                          :label="ct.conserva[1]"
                          :disabled="noEdit"
                        ></v-input_n>
                      </div>
                    </v-sheet>
                  </div>

                  <div class="columna pt-6" style=" width:700px">                                     
                    <uiObs3
                        :record="record"
                        :schema="ct"
                        :schemaComponente="ct.obs"                        
                        :edicion="!noEdit">
                      </uiObs3> 
                  </div>
                </div>
              </div>
            </v-sheet>


            <!-- Ventana de Componentes Extra  -->      
            <component      
              :is="componenteDinamico"
              syncUpdate
              :storeRaiz="storeName"
              :masterStore="storeName"              
              :readOnly="readOnly"
              :viewMtoOnly="viewMtoOnly"
              tipoDocumento='9'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>

          </div>
        </template>
    </dualTemplate>    
  </div>
</template>


<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";  
  import dualTemplate from "@/components/dualTemplate";
  import ctrlF from "@/components/ctrlF";
  import commonDocsF from "@/components/commonDocsF.vue";
  
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraMto, baseBtraExtra, dualTemplate, ctrlF, commonDocsF },
    props: {},

    data() {
      return {
        stIni: {
          api: "cemenM",
          name:"cemenM",
          titulo:"Mto Cementerios",  
          recordAccess:"local",
          mView:'',
          pView:[]
        },

        itemsGrupo: []
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");        

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { "accion":'documentos', "btn": "docs" }          
        ];
      },


      // COMPONENTES DINAMICOS (asignación)
      //
      documentos() {
        this.componenteDinamico= 'commonDocsF';
      },

      
    }
  };
</script>
