<!-- Finder particular de Cementerios -->

<template>
  <div class="cemenF" v-if="loaded">

    <div class="conflex" style="justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor">

          <!-- <div v-html="info"></div> -->
        
          <div style="display:flex; align-items:center">
            <div class="columna" style="width:40rem">
              <div class="conflex">
                <uiText                 
                  v-model="ct.nombre[2]"
                  :label="ct.nombre[1]"
                  @Enter="eventFiltro('buscar')">            
                </uiText>

                <v-btn
                  style="margin-left:5px"
                  v-bind="$cfe.btn.busca"
                  @click="eventFiltro('buscar')">            
                    <v-icon>{{ $cfe.btn.busca.icono }}</v-icon>
                </v-btn>
              </div>
              
              <ctrlF :ct="ct.loc"></ctrlF>
              
            </div>
          </div>


          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"           
            dense
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:top_btns="{}">         
                <div style="padding:10px 0 0 10px;">

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>
                  
                </div>
              </template>           

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>


      <!-- Mto Cementarios -->
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>

    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto"; 
  import ctrlF from "@/components/ctrlF";
  import cemenM from "@/components/cemenM";
  
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, ctrlF, cemenM },
    props: {},

    data() {
      return {
        stIni: {
          api: "cemenF",
          titulo:"Cementerios", 
          name:"cemenF",
          mView:'cemenM',
          pView:[]
        },

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Cementerio", value: "name", width: "40%" },
                { text: "Actualizado", value: "modificado", width: "10%" },
                { text: "Origen", value: "atlas", width: "40%" }          
          ]}
        ];

        this.headerGrid= this.headerSet();

      },

    }
  };
</script>
