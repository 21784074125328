<!-- Control Finder -->

<!-- Sin Botones
        - tipoView: N (no muestra ningún botón) -->

<!-- Finder (Sólo búsqueda)
        - tipoView: FC (no muestra Mto) -->

<!-- Finder con Mantenimiento
     Se necesitan la configuración de 3 variables, estas variables
     se puedes definir en el schema o enviar dinámicamente al ctrlF:
        - tipoView:
            * FCV=> finder con vista mto
            * FCA=> finder con mto virtual
            * FA=> sólo mto virtual, sin icono 'lupa' de finder

        - mView: nombre del API de ese mto
        - ctView: array de controles (solo para mto virtuales)
-->

<template>
    <div class="ctrlF">

    <v-menu offset-y>
        <template v-slot:activator="{on}">
          <v-text-field
              v-if="newCt"
              v-bind='{...$attrs, ...$input}'
              v-model="newCt[4].comp.valueAux"
              :label="$label"
              :disabled="edicion"
              readonly>

                <template slot="append" v-if="tipoView!= 'N'">
                  <v-icon v-if="edicion && (newCt[2]!=0 && newCt[2]!='') && tipoView!= 'FA'" @click="clear">{{ "mdi-close" }}</v-icon>
                  <v-icon v-if="edicion && tipoView!= 'FA'" @click="openFinder">{{ icono }}</v-icon>
                  <v-icon v-if="showIconView && (newCt[2]!=0 && newCt[2]!='')" @click="openMto">{{ 'mdi-eye' }}</v-icon>
                </template>

                <!-- icono Menú -->
                <template v-slot:prepend-inner v-if="menu">
                  <v-icon v-on="on" :disabled="!edicion">{{ "mdi-menu-down" }}</v-icon>
                </template>
          </v-text-field>
        </template>

        <!-- Lista de opciones del menú -->
        <v-list>
          <div style="text-align:center"> Seleccione opción </div>
          <v-divider></v-divider>
          <v-list-item link v-for="(item, index) in menu" :key="index">
            <v-list-item-title @click="$emit('onMenu', item)">{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
    </v-menu>

    </div>
</template>



<script lang="js">

    export default {
      props: {
        fn: { type:Function, default: null },                       // función que ejecuto al extraer
        label: { type: String, default: "" },
        ct: { type:[Array, Object], default: null },                // array de controles
        edicion: { type: Boolean, default: true },                  // (true/false). habilito los btns para editar el ctrlfinder (búsqueda, eliminación)
        icono: { type: String, default: "mdi-magnify" },            // icono por defecto de buscar
        tipoView: { type: String, default: "" },                    // tipo de vista (FC, FCV, FCA)
        ctView: { type: Object, default: ()=> null},                // array de controles
        mView: { type: String, default: "" },                       // nombre mto
        finder: { type: String, default:'' },                       // finder a ejecutar. Si no recibo nada se ejecuta el del ct
        syncAccion: { type: String, default: '' },                  // acción a ejecutar para cargar en el componente a mostrar
        masterStore: { type: String, default: '' },                 // nombre del store asociado
        masterRecordField: { type: String, default: undefined },    // campo relacionado con el Maestro
        menu: { type: Array, default: ()=> null},                  // array de controles
      },

      data() {
        return {
          newCt:{}
        }
      },


      created() {

        this.$watch("ct", {
          immediate: true,
          deep:true,

          handler() {
            this.newCt= this.ct;

            if (this.tipoView) this.newCt[4].comp.tipoView= this.tipoView;
            if (this.ctView) this.newCt[4].comp.ctView= this.ctView;
            if (this.mView) this.newCt[4].comp.mView= this.mView;
          }
        });

      },


      computed: {

          // muestro icono para ver el Mto
          // si el tipo de vista es FCV o FCA, lo muestro (extraer y ver)
          // si es FC, no lo muestro (solo extraer)
          showIconView() {
            if (!this.newCt[4].comp.tipoView || this.newCt[4].comp.tipoView== 'FC') return false;
            return true;
          },


          // devuelvo el value del control
          valueCt() {
            if (!this.newCt) return null;
            return this.newCt[2];
          },


          clearableCtrl() {
            return false;
          },


          $label() {
            if (this.label!='')   return this.label;
            if (this.newCt==null) return '';
            return !this.newCt[1]? '' : this.newCt[1];
          },

      },


      methods:
      {
        // abrimos modal Finder
        openFinder() {
          if (!this.finder && !this.newCt[4].comp.finder) return;

          // Evento al pulsar icono de busqueda
          this.$emit('beforeFinder', this.newCt);

          // lanzo commonF con ct y funcion que ejecutaria al extraer
          this.$root.$commonF.openFinder({
            ct:this.newCt,
            componenteName:(this.finder || this.newCt[4].comp.finder),
            fn:this.fn,
            accion:this.syncAccion,
            store:this.masterStore,
            recordField:this.masterRecordField
          });
        },


        // mostramos mto
        openMto() {
          this.$root.$commonF.openMto({
            ct:this.newCt,
            componenteName:(this.finder || this.newCt[4].comp.finder),
            fn:null,
            accion:0,
            store:'',
            recordField:undefined
          });
        },


        // borrar datos schema
        clear() {
          this.newCt[2]= "";
          this.newCt[4].comp.valueAux= "";
          this.$emit('clear');
        },

      },


      watch: {
        valueCt() {
          if (!this.valueCt) return;
          this.$emit('change', this.newCt);
        }
      }

    };
</script>


