<!-- Base Grid -->

<!--
props:
  - store: Store propio asociado (obligatorio)
  - headers: Definición de las cabeceras (obligatorio)
  - ordenar: Muestra botones para ordenar lineas (por defecto: false)
  - tipoMX: Selecciona como mostrar el mantenimiento del MX (expansible o modal).
          Es obligatorio definir en el padre la propiedad 'show-expand' para indicar que es un finder expansible (default: expand)
  - multipleSelect: true permite multiselect (por defecto: false)
                Para mostrar checkboxs por lineas hay que definir la propiedad 'show-select' en el padre
  - expansible: Nombre del Store del Mto MX

slots:
  - expansibleBtra: botonera en expansible panels.
  - expansibleMX: vista en expansible panels.
  - gridTop: vista parte superior del Grid.
  - selectMultiple: checkbox de selección simple/múltiple.
-->



<template>
  <div>

    <v-data-table
      v-model="masterRecordsSelected"
      v-bind="$attrs"
      class="baseGridMD"
      :headers="headers"
      :items="recordsGrid"
      single-expand
      :show-expand="expansible!= ''"
      :expanded.sync="expansibleSync"
      :single-select="!multipleSelect"
      :height="height"
      :item-key="itemKey"
      
      @dblclick:row="clkRow">

        <!-- Botones expansible -->
        <template v-slot:[`item.data-table-expand`]="{item,isExpanded}">

          <!-- Pendiente: sacar los botones de ordenar para que también funciones
               cuando no sea un finder expansible -->
          <div v-if='ordenando && !noEdit' class="conflex">
            <div style="color:green; display:flex">
              <v-btn icon><v-icon @click="orden(item,'down')" color="green">mdi-arrow-down-bold</v-icon></v-btn>
              <v-btn icon><v-icon @click="orden(item, 'up')"  color="green">mdi-arrow-up-bold</v-icon></v-btn>
            </div>
          </div>

          <div v-else>
            <div class="conflex">
              <!--
                Botones expansible.
                Defino botón de ver para abrir/cerrar expansible
                Se añade a ese botón los definidos en el slot 'expansibleBtra'
                Al pulsar dobleClick en cualquier parte de la fila, se ejecuta fn clkRow que
                abre/cierra expansible y selecciona/deselecciona la linea pulsada
              -->
              <v-btn v-bind="$cfe.default.btra.row">
                <v-icon v-bind="$cfe.default.btra.row.icons"
                      @click="clkRow(null, {isExpanded, item})">mdi-eye
                </v-icon>
              </v-btn>

              <slot name="expansibleBtra" :item="item"></slot>
            </div>
          </div>
        </template>


        <!-- Expansible -->
        <template v-if="tipoMX=='expand'" v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pt-2 pb-1">
            <slot name="expansibleMX" :item="item" :headers="headers"></slot>
          </td>
        </template>


        <!-- Botones top -->
        <template v-slot:top="{}">
          <div class="conflex" :style="!ordenando? 'justify-content:space-between' : 'justify-content:flex-end'">
            <template v-if="!ordenando">
              <slot name="gridTop"></slot>
            </template>

            <v-btn v-if="ordenar  && !noEdit"
              :disabled="!noEdit1"
              color="blue-grey lighten-1"
              small
              title="Orden de lineas"
              style="margin-right:10px"
              @click="clkOrdenar">
                <span style="color:white">{{ordenando? "Finalizar":"Ordenar"}}</span>
                <v-icon style="color:white"> mdi-swap-vertical-bold</v-icon>
            </v-btn>
          </div>
        </template>


        <!-- creo slots si lo tiene definido en el array -->
        <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">

          <div v-if="header.slot" :key="header.value">
            <slot :name="[`${header.value}`]" :item="item">
              {{ item[header.value] }}
            </slot>
          </div>

          <!-- aplicar filtro o style si lo tiene definido en la array -->
          <div v-else :key="`${header.value}`">
            <div :style="header.style ? header.style : ''">
              {{
                header.filtro
                  ? $options.filters[header.filtro](item[header.value], header.arg)
                  : item[header.value]
              }}
            </div>
          </div>
        </template>

        <!-- Slot para personalizar el checkbox de selección simple/múltiple -->
        <template v-slot:[`header.data-table-select`]="{ on, props }">
          <slot name="selectMultiple">
            <v-simple-checkbox
              v-bind="props"
              v-on="on"
            />
          </slot>
        </template>
    </v-data-table>

    <!-- Modal -->
    <div v-if="tipoMX=='modal'">
      <v-dialog
        content-class="modal" persistent
        v-model="modal">
          <baseHeader
            :cfg="$cfe.headers.mto"
            @onEvent="cerrarModal">
          </baseHeader>

          <slot name="expansibleMX" :item="masterRecordsSelected"></slot>
      </v-dialog>
    </div>
  </div>
</template>



<script>

  import baseHeader from "@/base/baseHeader";

  export default {
    components: { baseHeader },
    props: {
      store: { type: String, default: "", require:true },
      headers: { type: Array, default: () => [], require:true },
      ordenar: {type: Boolean, default: false},
      tipoMX: { type: String, default: 'expand' },
      multipleSelect: { type:Boolean, default:false },
      expansible: { type:String, default:'' },
      height: { type:String, default:'' },
      itemKey: { type: String, default: 'id' }
    },



    methods: {

      // entro al hacer click en el botón de ORDENDAR/FIN OPRDENAR
      clkOrdenar() {
        if (!this.ordenando) this.$store.commit(this.store + '/recordsSelectedSet', []);
        this.ordenando=!this.ordenando;
      },


      // entro al seleccionar/pulsar una fila
      clkRow(row, {isExpanded, item}) {

        // si está ordenando las lineas, salgo
        if (this.ordenando) return;

        // compruebo si es un Mto expansible
        if (this.expansible) {
          // compruebo si el Mto expansible está en edición
          if (!this.noEditMX) {
            this.$root.$alert.open('Tiene una linea en edición. GUARDE o CANCELE los cambios', 'error');
            return;
          }

          // si el expansible está abierto, lo cierro
          if (isExpanded) {
            this.$store.commit(this.store + '/recordsSelectedSet', []);
            return;

          }else {
            this.$emit('onEvent', { accion:0, item:item });
          }
        }

        // selección única
        if (!this.multipleSelect) {
          this.$store.commit(this.store + '/recordsSelectedSet', [item]);
          return;
        }

        // selección múltiple
        let records= JSON.parse(JSON.stringify(this.masterRecordsSelected));
        if (records.filter(elem => elem.id == item.id).length) {
          // ya existe la fila en el array de elementos seleccionados... la elimino
          records= records.filter(elem => elem.id !== item.id);

        }else {
          // NO existe la fila en el array de elementos seleccionados... la añado
          records.push(item);
        }

        // actualizo array de elementos seleccionados en el store
        this.$store.commit(this.store + '/recordsSelectedSet', records);
      },


      //
      orden(item,dir) {
        let arrayRecords = JSON.parse(JSON.stringify(this.recordsGrid));

        // si no hay mas de un elemento, return
        if (arrayRecords.length< 2) return;

        // encuentro el index del item en la array
        let index = arrayRecords.findIndex((e) => e.id == item.id);
        item = arrayRecords[index];

        //
        if (dir === "down") {
          if (index !== -1 && index < arrayRecords.length - 1) {
            arrayRecords[index] = arrayRecords[index + 1];
            arrayRecords[index + 1] = item;
          }

          // vuelta primera posicion
          if (index === arrayRecords.length - 1) {
            arrayRecords.splice(index, 1);
            arrayRecords.splice(0, 0, item);
          }
        }

        //
        if (dir === "up") {
          if (index > 0) {
            arrayRecords[index] = arrayRecords[index - 1];
            arrayRecords[index - 1] = item;
          }

          // vuelta ultima posicion
          if (index === 0) {
            arrayRecords.splice(index, 1);
            arrayRecords.splice(arrayRecords.length, 0, item);
          }
        }

        arrayRecords = arrayRecords.map((e, index) => {
          e.orden = index;
          return e;
        });

        // actualizo store
        this.$store.commit(this.store + '/recordReadSet1', arrayRecords);
        this.$store.commit("data2State", { modulo: this.store, prop: "change1", value: true });
      },





      //
      cerrarModal() {
        this.$store.commit(this.store + '/recordsSelectedSet', []);
      },


    },


    computed: {

      noEdit() {
        return this.$store.getters[this.store + "/noEdit"];
      },

      noEdit1() {
        return this.$store.getters[this.store + "/noEdit1"];
      },

      recordsGrid() {
        return this.$store.getters[this.store + "/recordsGrid"];
      },

      masterRecordsSelected: {
        get() {
          return this.$store.state[this.store].recordsSelected;
        },

        set(val) {
          this.$store.commit(this.store + '/recordsSelectedSet', val);
        }
      },

      ordenando: {
        get() {
          return this.$store.state[this.store].ordenando;
        },

        set(val) {
          this.$store.commit(this.store + '/data2State', { prop:'ordenando', value:val });
        }
      },

      // cuando el tipo de MX es un modal, controlo que se muestre o no el modal dependiendo si hay
      // algún Mto seleccionado
      modal() {
        if (this.masterRecordsSelected.length) return true;
        return false;
      },

      expansibleSync: {
        get() {
          if (this.expansible) return this.masterRecordsSelected;
          return [];
        },

        set() {}
      },

      // (true/false) edición expansible
      noEditMX() {
        if (!this.expansible) return true;
        if (!this.$store.state[this.expansible]) return true;
        return this.$store.getters[this.expansible + "/noEditMX"];
      },


    },

  };
</script>
